import React, { useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import config from './config'; // Adjust the import path if needed

function Record({ onClose, onTranscription }) {
    const mediaRecorderRef = useRef(null);
    const audioChunks = useRef([]);
    const canvasRef = useRef(null);
    const audioContextRef = useRef(null);
    const analyserRef = useRef(null);
    const dataArrayRef = useRef(null);
    const microphoneRef = useRef(null);
    const sourceRef = useRef(null);
    const videoStreamRef = useRef(null); // Add ref to handle video stream

    const visualize = () => {
        if (!canvasRef.current) return;
        const canvas = canvasRef.current;
        const canvasContext = canvas.getContext('2d');

        const renderFrame = () => {
            if (!analyserRef.current || !dataArrayRef.current) return;

            analyserRef.current.getByteTimeDomainData(dataArrayRef.current);

            canvasContext.fillStyle = 'rgb(200, 200, 200)';
            canvasContext.fillRect(0, 0, canvas.width, canvas.height);

            canvasContext.lineWidth = 2;
            canvasContext.strokeStyle = 'rgb(0, 0, 0)';
            canvasContext.beginPath();

            const sliceWidth = (canvas.width * 1.0) / analyserRef.current.frequencyBinCount;
            let x = 0;

            for (let i = 0; i < analyserRef.current.frequencyBinCount; i++) {
                const v = dataArrayRef.current[i] / 128.0;
                const y = (v * canvas.height) / 2;

                if (i === 0) {
                    canvasContext.moveTo(x, y);
                } else {
                    canvasContext.lineTo(x, y);
                }

                x += sliceWidth;
            }

            canvasContext.lineTo(canvas.width, canvas.height / 2);
            canvasContext.stroke();

            requestAnimationFrame(renderFrame);
        };

        renderFrame();
    };

    const sendToOpenAI = useCallback(async (audioBlob) => {
        const formData = new FormData();
        formData.append('file', audioBlob, 'audio.wav'); // Append file with a name
        formData.append('model', 'whisper-1'); // Model name for OpenAI STT
        formData.append('prompt', '如果沒有聲音,請說明,並請用繁體中文回答。');

        try {
            const response = await axios.post('https://api.openai.com/v1/audio/transcriptions', formData, {
                headers: {
                    'Authorization': `Bearer ${config.openAiApiKey}`, // Use the API key from config.js
                    'Content-Type': 'multipart/form-data'
                }
            });

            const transcription = response.data.text;
            onTranscription(transcription); // Call the onTranscription function from props with the result
        } catch (error) {
            console.error('Error sending audio to OpenAI:', error);
        }
    }, [onTranscription]);

    useEffect(() => {
        audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
        analyserRef.current = audioContextRef.current.createAnalyser();
        analyserRef.current.fftSize = 2048;

        // Request microphone and camera access, start recording
        navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then((stream) => {
            microphoneRef.current = stream;
            videoStreamRef.current = stream; // Store video stream reference

            sourceRef.current = audioContextRef.current.createMediaStreamSource(stream);
            sourceRef.current.connect(analyserRef.current);

            dataArrayRef.current = new Uint8Array(analyserRef.current.frequencyBinCount);
            visualize();

            const mediaRecorder = new MediaRecorder(stream);
            mediaRecorderRef.current = mediaRecorder;
            mediaRecorder.start();

            mediaRecorder.ondataavailable = (event) => {
                audioChunks.current.push(event.data);
            };

            mediaRecorder.onstop = async () => {
                const audioBlob = new Blob(audioChunks.current, { type: 'audio/wav' }); // Specify audio type
                audioChunks.current = [];

                // Send audioBlob to OpenAI for STT processing
                await sendToOpenAI(audioBlob);
            };
        }).catch((error) => {
            console.error('Error accessing microphone or camera', error);
        });

        // Cleanup function to stop all media streams and reset recorder
        return () => {
            if (mediaRecorderRef.current) {
                mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop());
                mediaRecorderRef.current = null;
            }
            if (microphoneRef.current) {
                microphoneRef.current.getTracks().forEach(track => track.stop());
                microphoneRef.current = null;
            }
            if (videoStreamRef.current) {
                videoStreamRef.current.getTracks().forEach(track => track.stop()); // Stop video tracks
                videoStreamRef.current = null;
            }
            if (audioContextRef.current && audioContextRef.current.state !== 'closed') {
                audioContextRef.current.close().then(() => {
                    audioContextRef.current = null;
                });
            }
        };
    }, [sendToOpenAI]);

    const handleStopRecording = () => {
        onClose(); // Call the onClose function from props to close the modal
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop();
        }
        // Ensure all media streams are stopped
        if (microphoneRef.current) {
            microphoneRef.current.getTracks().forEach(track => track.stop());
        }
        if (videoStreamRef.current) {
            videoStreamRef.current.getTracks().forEach(track => track.stop());
        }
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '50px' }}>
            {/* Modal for waveform visualization and stop button */}
            <div
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '20px',
                    zIndex: 1000,
                }}
            >
                {/* Content area with white background */}
                <div
                    style={{
                        position: 'relative',  // Set to relative to position the close button absolutely inside it
                        backgroundColor: 'white',
                        borderRadius: '10px',
                        padding: '30px',
                        textAlign: 'center',
                        width: '80%',
                        maxWidth: '600px',
                    }}
                >
                    <h3 style={{ marginBottom: '40px' }}>您可以這樣問</h3>

                    {/* List of example questions with larger font size */}
                    <ul
                        style={{
                            textAlign: 'center',
                            marginBottom: '20px',
                            listStyleType: 'none',
                            padding: 0,
                            fontFamily: 'Verdana, sans-serif',
                            fontSize: '1.5rem', // Larger font size for the text
                        }}
                    >
                        {[
                            '忘了名字,可以列出您有的名字讓我挑選嗎?',
                            '我要找張先生,但是忘了名字',
                            '我要找管理部的同事,但是我忘了名字?',
                            '我要找 袁龍聖, 請幫我連繫',
                            '我與 Kiki有約, 請幫我連絡一下',
                            '說一個笑話'
                        ].map((text, index) => (
                            <li
                                key={index}
                                style={{
                                    padding: '16px 20px', // Increased padding for more spacing
                                    marginBottom: '10px', // Slightly larger margin for better separation
                                    backgroundColor: '#e6f4ff',
                                    color: '#0056b3',
                                    borderLeft: '6px solid #007bff', // Slightly larger border for emphasis
                                    borderRadius: '6px', // Slightly larger radius for more rounded corners
                                    cursor: 'pointer',
                                    transition: 'background-color 0.3s ease',
                                }}
                                onMouseEnter={(e) =>
                                    (e.currentTarget.style.backgroundColor = '#d0e7ff')
                                }
                                onMouseLeave={(e) =>
                                    (e.currentTarget.style.backgroundColor = '#e6f4ff')
                                }
                            >
                                {text}
                            </li>
                        ))}
                    </ul>

                    {/* Canvas for the waveform */}
                    <canvas
                        ref={canvasRef}
                        width="300"
                        height="200"
                        style={{
                            border: '1px solid #ccc',
                            borderRadius: '10px',
                            marginBottom: '20px',
                        }}
                    />

                    {/* Center the stop button */}
                    <div
                        style={{
                            display: 'flex',  // Use flexbox
                            justifyContent: 'center',  // Center horizontally
                            alignItems: 'center',  // Center vertically
                            marginTop: '20px'  // Optional margin for spacing
                        }}
                    >
                        <button
                            onClick={handleStopRecording}
                            style={{
                                width: '100px',
                                height: '100px',
                                fontSize: '3rem',
                                borderRadius: '50%', // Make the button circular
                                backgroundColor: '#000', // Black background for the button
                                display: 'flex', // Flexbox for centering
                                alignItems: 'center', // Center vertically
                                justifyContent: 'center', // Center horizontally
                                color: 'white', // White color for the square
                                border: 'none',
                                cursor: 'pointer',
                            }}
                        >
                            <div
                                style={{
                                    width: '50%',
                                    height: '50%',
                                    backgroundColor: 'white', // White square in the center
                                }}
                            />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Record;
