import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import axios from 'axios';
// import { v4 as uuid } from 'uuid';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaMicrophone, FaSyncAlt, FaPlay } from 'react-icons/fa';
import { RiSpeakFill } from 'react-icons/ri';
import config from './config';
import Room from './Room'; // Import Room component
import './Help.css';
import Record from './Record'; // Import Record component
// import Call from './Call'; // Import the Call component

function Help() {
    const [messages, setMessages] = useState([
        { role: 'assistant', content: '歡迎光臨鴻翊國際,請按麥克風語音找人,或按快捷鍵直接視訊通話' },
    ]);
    const [input, setInput] = useState('');
    // const [isListening] = useState(false);
    const [peopleOptions, setPeopleOptions] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [roomID, setRoomID] = useState(null);
    const [loading, setLoading] = useState(false); // State to track loading status
    // const [, setUploadedImagePath] = useState(''); // State to store the uploaded image URL
    const [responseData,] = useState(null); // State to store the AI response data
    const chatBoxRef = useRef(null);
    const audioRef = useRef(null);
    const blipSound = useMemo(() => new Audio('/blip.mp3'), []);
    const cameraStreamRef1 = useRef(null);
    const cameraStreamRef2 = useRef(null);
    const [showRecordModal, setShowRecordModal] = useState(false);
    const [showPeopleOptionsModal, setShowPeopleOptionsModal] = useState(false);
    const [transcription, setTranscription] = useState('');
    const [ttsStatus, setTtsStatus] = useState('silence'); // State to track TTS status
    const [showInputSection,] = useState(false); // Change to 'true' if you want it visible by default
    const [isMicDisabled, setIsMicDisabled] = useState(false);
    const [showStartModal, setShowStartModal] = useState(true); // State to control the visibility of the start modal
    const [isListening, setIsListening] = useState(false);
    const microphoneRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false); // State to manage loading effect
    // New state for department loading
    const [isDepartmentLoading, setIsDepartmentLoading] = useState(false);

    const onDutyPerson = config.officeContacts.find(contact => contact.on_duty);


    const [startIndex, setStartIndex] = useState(0); // Track start index for cycling departments
    // In your Help component
    const [showCallModal, setShowCallModal] = useState(false); // Modal state

    const [timer, setTimer] = useState(300); // 5 minutes in seconds (300 seconds)



    // Function to toggle modal
    const toggleCallModal = () => {
        console.log("Toggling Modal: Current State:", showCallModal);

        // Check if the modal is being closed
        if (showCallModal) {
            // If the modal is currently open, and this action is closing it, reload the page
            window.location.reload();
        } else {
            // If the modal is being opened, simply toggle it without reloading
            setShowCallModal(prevState => !prevState);
            console.log("Toggled Modal: New State:", !showCallModal); // Log the new state after toggling
            setTimer(300); // Reset the timer to 5 minutes when the modal opens
        }
    };

    // Countdown effect
    useEffect(() => {
        let countdownInterval;
        if (showCallModal && timer > 0) {
            countdownInterval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1); // Decrease the timer every second
            }, 1000);
        } else if (timer === 0) {
            // Close modal when timer reaches 0
            setShowCallModal(false);
            window.location.reload(); // Reload page after closing the modal
        }

        return () => clearInterval(countdownInterval); // Cleanup interval on unmount
    }, [showCallModal, timer]);


    const groupContactsByDepartment = () => {
        const departments = {};
        config.officeContacts.forEach((contact) => {
            if (!departments[contact.department]) {
                departments[contact.department] = [];
            }
            departments[contact.department].push(contact);
        });
        return Object.entries(departments); // Returns an array of [department, contacts] pairs
    };

    // Group contacts by department
    const groupedDepartments = groupContactsByDepartment();
    const totalDepartments = groupedDepartments.length;

    const getNextDepartments = (start) => {
        const groupedContacts = groupContactsByDepartment();
        const displayedDepartments = [];
        for (let i = 0; i < 3; i++) { // Show two departments
            displayedDepartments.push(groupedContacts[(start + i) % groupedContacts.length]);
        }
        return displayedDepartments;
    };

    // Cycle through departments every 10 seconds and show loading
    useEffect(() => {
        const intervalId = setInterval(() => {
            setIsDepartmentLoading(true); // Show loading effect

            setTimeout(() => {
                setStartIndex((prevIndex) => (prevIndex + 2) % totalDepartments); // Update startIndex by 2 departments
                setIsDepartmentLoading(false); // Hide loading after 1 second
            }, 1000); // Loading for 1 second
        }, 15000); // Update every 10 seconds

        return () => clearInterval(intervalId); // Cleanup on unmount
    }, [totalDepartments]);




    const handleStop = () => {
        console.log('handleStop called. Initial states:', {
            isListening,
            ttsStatus,
            isMicDisabled,
            loading,
        });

        // Cancel any ongoing API requests
        axios.CancelToken.source().cancel('Operation canceled by the user.');

        // Stop camera capture for both streams
        if (cameraStreamRef1.current) {
            const tracks = cameraStreamRef1.current.getTracks();
            tracks.forEach((track) => track.stop());
            cameraStreamRef1.current = null;
            console.log('Camera stream 1 stopped.');
        } else {
            console.log('Camera stream 1 was not active.');
        }

        if (cameraStreamRef2.current) {
            const tracks = cameraStreamRef2.current.getTracks();
            tracks.forEach((track) => track.stop());
            cameraStreamRef2.current = null;
            console.log('Camera stream 2 stopped.');
        } else {
            console.log('Camera stream 2 was not active.');
        }

        // Use refs to access video elements and stop them
        const videoRef1 = document.getElementById('cameraVideo1');
        const videoRef2 = document.getElementById('cameraVideo2');

        if (videoRef1 && videoRef1.srcObject) {
            const stream = videoRef1.srcObject;
            const tracks = stream.getTracks();
            tracks.forEach((track) => track.stop());
            videoRef1.srcObject = null;  // Clear the video source
            videoRef1.pause();  // Pause the video playback
            console.log('Video preview 1 stopped and source cleared.');
        } else {
            console.log('Video preview 1 was not active or has no stream.');
        }

        if (videoRef2 && videoRef2.srcObject) {
            const stream = videoRef2.srcObject;
            const tracks = stream.getTracks();
            tracks.forEach((track) => track.stop());
            videoRef2.srcObject = null;  // Clear the video source
            videoRef2.pause();  // Pause the video playback
            console.log('Video preview 2 stopped and source cleared.');
        } else {
            console.log('Video preview 2 was not active or has no stream.');
        }

        // Ensure the camera and microphone streams from Record.js are stopped
        if (microphoneRef.current) {
            microphoneRef.current.getTracks().forEach(track => track.stop());
            microphoneRef.current = null;
            console.log('Microphone stream stopped.');
        }

        // Stop microphone listening
        if (isListening) {
            setIsListening(false);
            console.log('Microphone listening stopped.');
        } else {
            console.log('Microphone was not listening.');
        }

        // Check and log TTS status
        console.log('Current TTS status before stopping audio:', ttsStatus);
        if (ttsStatus === 'speaking') {
            console.log('TTS is currently speaking. Preparing to stop.');
        } else {
            console.log('TTS is not speaking.');
        }

        // Stop audio playback
        if (audioRef.current) {
            audioRef.current.pause();  // Pause the audio
            audioRef.current.currentTime = 0;  // Reset the audio playback time to the beginning
            audioRef.current = null;  // Clear the audio reference
            console.log('Audio playback stopped.');

            // Explicitly set TTS status to "silence"
            setTtsStatus('silence');
            console.log('TTS status set to "silence".');
        } else {
            console.log('No audio was playing.');
        }

        // Reset any ongoing state or processes
        setLoading(false);
        setPeopleOptions([]);
        setShowModal(false);
        setShowRecordModal(false);
        setShowPeopleOptionsModal(false);

        // Enable the microphone button after all processes have been stopped
        setIsMicDisabled(false);
        console.log('Microphone button enabled.');

        // Debugging the state after all actions
        console.log('Final states after handleStop execution:', {
            isListening,
            ttsStatus,
            isMicDisabled,
            loading,
        });

        console.log('All processes stopped and microphone re-enabled.');
    };




    const handleStart = () => {
        setShowStartModal(false);

        // Create and play the pre-recorded welcome message
        audioRef.current = new Audio('/welcome.mp3');
        audioRef.current.play();
    };


    // Inside your Help component

    // Function to handle text-to-speech
    const handleTextToSpeech = useCallback(async (text) => {
        try {
            // Stop and reset current audio if it's already playing
            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current.currentTime = 0;
                console.log('Existing audio stopped and reset.');
                audioRef.current = null;  // Clear previous reference
            }

            // Call API to get TTS audio
            const response = await axios.post(
                'https://api.openai.com/v1/audio/speech',
                {
                    model: 'tts-1',
                    input: text,
                    voice: 'nova',
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${config.openAiApiKey}`,
                    },
                    responseType: 'blob',
                }
            );

            // Create new audio element from response
            const audioUrl = URL.createObjectURL(new Blob([response.data], { type: 'audio/mpeg' }));
            const audio = new Audio(audioUrl);
            audioRef.current = audio;

            // Debugging: Check if audio element is created
            console.log('New audio element created:', audio);

            // Set TTS status to "speaking" when audio starts
            audio.onplay = () => {
                setTtsStatus('speaking');
                console.log('TTS started speaking.');
            };

            // Set TTS status to "silence" when audio ends
            audio.onended = () => {
                setTtsStatus('silence');
                audioRef.current = null;  // Clear the audio reference when finished
                console.log('TTS finished speaking and status set to "silence".');
            };

            // Play the audio
            audio.play().catch((err) => console.error('Error playing audio:', err));

            // Debugging: Confirm the play action is initiated
            console.log('Audio play action initiated.');
        } catch (error) {
            console.error('Error fetching or playing audio:', error);
        }
    }, []);




    useEffect(() => {
        if (peopleOptions.length > 0) {
            setIsLoading(true); // Show loading effect
            setTimeout(() => {
                setIsLoading(false); // Hide loading effect after 1 second
                setShowPeopleOptionsModal(true); // Show the modal
            }, 2000); // Delay for 1 second (1000 milliseconds)
        } else {
            setShowPeopleOptionsModal(false);
        }
    }, [peopleOptions]);


    useEffect(() => {
        const startCameraStream = (ref, videoElementId) => {
            navigator.mediaDevices.getUserMedia({ video: true })
                .then((stream) => {
                    ref.current = stream;
                    const videoElement = document.getElementById(videoElementId);
                    if (videoElement) {
                        videoElement.srcObject = stream;
                        videoElement.play();
                    }
                })
                .catch((error) => {
                    console.error(`Error accessing the camera for ${videoElementId}:`, error);
                });
        };

        if (showPeopleOptionsModal) {
            startCameraStream(cameraStreamRef1, 'cameraVideo1');
        } else if (showModal) {
            startCameraStream(cameraStreamRef2, 'cameraVideo2');
        } else {
            handleCloseModal(cameraStreamRef1);
            handleCloseModal(cameraStreamRef2);
        }
    }, [showPeopleOptionsModal, showModal]);

    const handleCloseModal = (cameraRef) => {
        setShowModal(false);
        setShowPeopleOptionsModal(false);
        if (cameraRef.current) {
            const tracks = cameraRef.current.getTracks();
            tracks.forEach((track) => track.stop());
            cameraRef.current = null;
        }
    };

    useEffect(() => {
        if (chatBoxRef.current) {
            chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
        }
    }, [messages]);

    const handleSendMessage = useCallback(async (text = input) => {
        if (!text.trim()) return;

        const userMessage = { role: 'user', content: text };
        const updatedMessages = [...messages, userMessage];

        setMessages(updatedMessages);
        setInput('');
        setIsMicDisabled(true);  // Disable the microphone button

        try {
            const response = await axios.post(
                'https://api.openai.com/v1/chat/completions',
                {
                    model: 'gpt-4o-2024-08-06',
                    messages: [
                        {
                            role: 'system',
                            content: `你是鴻翊國際公司的一名接待員。以下是辦公室同事的聯絡名單：${config.officeContacts.map(contact => `${contact.department} ${contact.chinese_name} (${contact.name})，分機號碼：${contact.phone}`).join('，')}。如果使用者查詢某個部門並有多位同事，請列出所有同事的名字和手機號碼，並詢問他們要聯絡哪一位。當顯示聯絡人時，請告知使用者聯絡人姓名與手機號碼。如果有多於一位聯絡人，請只念出第一位的姓名與手機號碼，加上'等' 並說明總共有多少位同事。最後，請詢問使用者是否是您要找的人,並指示點擊下方的按鈕與聯絡人通話。請確保每個回應不超過100個字元。`,
                        },
                        ...updatedMessages,
                        { role: 'user', content: text } // Only the latest user m
                    ],
                    response_format: {
                        type: 'json_schema',
                        json_schema: {
                            name: 'office_contact_response',
                            strict: true,
                            schema: {
                                type: 'object',
                                properties: {
                                    contacts: {
                                        type: 'array',
                                        items: {
                                            type: 'object',
                                            properties: {
                                                name: { type: 'string' },
                                                chinese_name: { type: 'string' },
                                                department: { type: 'string' },
                                                phone: { type: 'string' },
                                            },
                                            required: ['name', 'chinese_name', 'department', 'phone'],
                                            additionalProperties: false,
                                        },
                                    },
                                    message: {
                                        type: 'string',
                                    },
                                },
                                required: ['contacts', 'message'],
                                additionalProperties: false,
                            },
                        },
                    }
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${config.openAiApiKey}`,
                    },
                }
            );

            const assistantMessageContent = response.data.choices[0].message.content;
            const structuredResponse = JSON.parse(assistantMessageContent);
            console.log('structuredResponse', structuredResponse);
            const { contacts, message } = structuredResponse;
            setMessages((prevMessages) => [...prevMessages, { role: 'assistant', content: message }]);
            setPeopleOptions(contacts);
            await handleTextToSpeech(message);
        } catch (error) {
            console.error('獲取助手回應時出錯:', error);
        } finally {
            setIsMicDisabled(false);  // Re-enable the microphone button after response or error
        }
    }, [input, messages, handleTextToSpeech]);

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSendMessage();
        }
    };

    const startListening = () => {
        console.log('Microphone button clicked!');
        setIsLoading(true); // Show the existing loading effect
        blipSound.play(); // Play the start sound

        setTimeout(() => {
            setIsLoading(false); // Hide loading effect after 1 second
            setShowRecordModal(true); // Show the Record modal
        }, 1000); // 1 second delay
    };


    useEffect(() => {
        if (transcription) {
            console.log('Current transcription:', transcription);
        }
    }, [transcription]);


    const reloadPage = () => {
        window.location.reload();  // Function to reload the page
    };

    const handlePersonClick = async (person, imageUrl) => {
        // Close the people options modal

        console.log('imageUrl', imageUrl)
        setShowPeopleOptionsModal(false);

        // Play a sound when a person is clicked
        blipSound.play();

        // Stop any currently playing audio
        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
            audioRef.current = null;
        }

        console.log('Selected Person:', person);

        // Generate a new room ID
        // const newRoomID = uuid();
        // setRoomID(newRoomID);

        function generateRoomID() {
            const timestamp = Date.now(); // 13-digit timestamp
            const randomChars = Math.random().toString(36).substring(2, 10); // 8 random alphanumeric characters
            return `code=${timestamp}${randomChars}`;
        }

        const newRoomID = generateRoomID();

        console.log(newRoomID)

        setRoomID(newRoomID);

        try {

            // Send person data to the API endpoint with room URL and uploaded image URL
            await axios.post(config.linenotify, {
                command: "line notify",
                person: {
                    name: person.name,
                    chinese_name: person.chinese_name,
                    phone: person.phone, // Include the phone number in the payload
                },
                // room_url: `${config.videoChatBaseUrl}/callmobile?${newRoomID}`, // Use the base URL from config
                room_url: `${config.videoChatBaseUrl}/call?${newRoomID}`, // Use the base URL from config
                imageUrl: imageUrl // Include the uploaded image URL
            });

            console.log('API Response: Image and person data sent successfully');

            // Open the main modal
            // setShowModal(true);
            // Redirect to the /call page with the generated newRoomID
            // window.location.href = `/call?${newRoomID}`;
            // Open the call modal instead of redirecting
            setShowCallModal(true);  // Show the modal with the iframe


        } catch (error) {
            console.error('Error during image capture, upload, or API call:', error);
        }
    };


    const uploadFile = async (image) => {
        const eventId = 'videochat'; // Replace with your actual event ID
        const url = `https://media.dtix.tw/uploadPic/${eventId}`;
        const filename = new Date().toISOString() + '.jpg'; // Generates a unique filename for the image
        const blob = await fetch(image).then(res => res.blob()); // Converts the base64 image to a blob

        let formData = new FormData();
        formData.append('file', blob, filename); // Prepares the form data for the file upload

        setLoading(true); // Set loading state to true

        try {
            const response = await axios.post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.status !== 200) {
                throw new Error('檔案上傳失敗');
            }

            const data = response.data;
            console.log('檔案上傳成功:', data);

            // Return the uploaded image URL
            return data.imgUrl;

        } catch (error) {
            console.error('檔案上傳失敗:', error);
            alert('檔案上傳失敗: ' + error.message);
            return null; // Return null if the upload fails
        } finally {
            setLoading(false); // Reset loading state
        }
    };



    // Function to capture image from camera and submit
    const handleCaptureAndSubmit = async (contact) => {
        try {
            // Start camera stream
            const stream = await navigator.mediaDevices.getUserMedia({ video: true });
            const videoElement = document.createElement('video');
            videoElement.srcObject = stream;

            // Wait for the video element to be fully loaded and playing
            await new Promise((resolve, reject) => {
                videoElement.onloadedmetadata = () => {
                    videoElement.play();
                    resolve();
                };
                videoElement.onerror = reject;
            });

            // Log the video dimensions for debugging
            console.log('Video element dimensions:', videoElement.videoWidth, videoElement.videoHeight);

            // Ensure the video element has loaded dimensions before continuing
            if (!videoElement.videoWidth || !videoElement.videoHeight) {
                throw new Error('Video element has no width or height.');
            }

            // Capture a screenshot
            const canvas = document.createElement('canvas');
            canvas.width = videoElement.videoWidth;  // Use video element's width
            canvas.height = videoElement.videoHeight; // Use video element's height
            const context = canvas.getContext('2d');
            context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

            // Convert to data URL (image)
            const imageUrl = canvas.toDataURL('image/jpeg');

            // Stop the camera stream after capture
            const tracks = stream.getTracks();
            tracks.forEach(track => track.stop());

            // Upload the image and get the imageUrl
            const uploadedImageUrl = await uploadFile(imageUrl);

            // Ensure the image was successfully uploaded
            if (uploadedImageUrl) {
                // Call the handlePersonClick function and pass the uploaded image URL
                handlePersonClick(contact, uploadedImageUrl);
            } else {
                console.error('Image upload failed.');
            }

        } catch (error) {
            console.error('Error capturing image:', error);
        }
    };




    return (
        <div className="container" style={{ width: '100%', height: '93%', maxWidth: '100%', margin: '0 auto', padding: '20px' }}>

            {isLoading && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black background outside the box
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 1100,
                    }}
                >
                    {/* White box container for the loading bars */}
                    <div>
                        {/* Dynamic bars container */}
                        <div style={{
                            display: 'flex',
                            gap: '10px', // Increased gap for larger effect
                        }}>
                            {/* Individual bars with animation */}
                            {[...Array(7)].map((_, index) => (
                                <div
                                    key={index}
                                    style={{
                                        width: '15px', // Increased width for larger bars
                                        height: '100px', // Increased height for larger effect
                                        backgroundColor: `hsl(${index * 50}, 70%, 50%)`, // Different color for each bar
                                        animation: `bounce 1.5s ease-in-out infinite`, // Longer duration for smoother animation
                                        animationDelay: `${index * 0.15}s`, // Stagger the animation start time
                                    }}
                                ></div>
                            ))}
                        </div>
                    </div>
                </div>
            )}




            {showStartModal && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(255, 255, 255, 0.8)', // 80% transparent white background
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 1050,
                    }}
                >
                    {/* Centered Button */}
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            zIndex: 2, // Ensure it's on top
                            backgroundColor: 'transparent', // Button container is transparent
                        }}
                    >
                        <button
                            className={`btn btn-microphone ${isListening ? 'listening' : ''}`} // Match the same class and condition
                            onClick={handleStart} // Use the same onClick function as before
                            style={{
                                width: '240px', // 1.5 times larger than the original 160px
                                height: '240px', // 1.5 times larger than the original 160px
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: '0',
                                borderRadius: '50%',
                                border: 'none',
                                cursor: isMicDisabled ? 'not-allowed' : 'pointer', // Same cursor style
                                opacity: isMicDisabled ? 0.5 : 1, // Same opacity based on isMicDisabled
                                backgroundColor: '#007bff', // You can set a specific color for the button
                                color: '#fff', // White color for the icon
                            }}
                            disabled={isMicDisabled} // Only use isMicDisabled to disable the button
                        >
                            <FaPlay size={120} /> {/* Start icon with increased size */}
                        </button>
                    </div>
                </div>
            )}



            {/* Existing content goes here */}
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '20px' }}>
                <img src="/datavan_icon.jpg" alt="Datavan Icon" style={{ maxWidth: '200px', marginRight: '20px' }} />
            </div>



            {/* Reload Button with Absolute Position */}
            <button
                onClick={reloadPage}  // Add reload function
                style={{
                    position: 'absolute',
                    top: '20px',  // Position the button at the top
                    right: '20px',  // Position the button to the right
                    backgroundColor: 'darkgrey',  // Dark grey background
                    color: 'white',  // White icon
                    border: 'none',
                    borderRadius: '50%',  // Circular shape
                    padding: '20px',  // Increase padding for larger size
                    cursor: 'pointer',
                    fontSize: '2rem',  // Increase font size for icon size
                    width: '90px',  // Set fixed width to ensure circular shape
                    height: '90px',  // Set fixed height to ensure circular shape
                    display: 'flex',  // Center icon inside button
                    justifyContent: 'center',  // Center icon horizontally
                    alignItems: 'center',  // Center icon vertically
                    zIndex: 1000  // Ensure button is above other elements
                }}
            >
                <FaSyncAlt />  {/* Reload icon */}
            </button>

            <div className="responsive-container" style={{ display: 'flex', justifyContent: 'space-between', height: '700px', width: '100%' }}>

                {/* Chat Box on the Left */}
                <div className="chat-box mb-3" ref={chatBoxRef} style={{ overflowY: 'auto', flex: '0 0 40%', marginRight: '20px', height: '120%' }}>
                    {messages.map((msg, index) => (
                        <div key={index} className={`message ${msg.role}`}>
                            <div className="bubble">
                                {msg.content}
                            </div>
                        </div>
                    ))}
                </div>

                {/* Microphone Button */}
                <div style={{ flex: '0 0 20%', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '70px 0' }}>
                    <button
                        className={`btn btn-microphone ${isListening ? 'listening' : ''}`}
                        onMouseDown={async () => {
                            await handleStop();
                            startListening();
                        }}
                        onTouchStart={async () => {
                            await handleStop();
                            startListening();
                        }}
                        style={{
                            width: '180px',
                            height: '180px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: '0',
                            borderRadius: '50%',
                            border: 'none',
                            cursor: isMicDisabled ? 'not-allowed' : 'pointer',
                            opacity: isMicDisabled ? 0.5 : 1,
                        }}
                        disabled={isMicDisabled}
                    >
                        {isListening ? <RiSpeakFill size={78} /> : <FaMicrophone size={78} />}
                    </button>
                </div>

                <div style={{ width: '100%' }}>
                    {onDutyPerson && (
                        <div style={{ marginBottom: '20px', padding: '15px', backgroundColor: '#f0f0f0', borderRadius: '10px', textAlign: 'center' }}>
                            <h3>值班人員</h3>
                            <button
                                className="btn text-white"
                                style={{
                                    fontSize: '1.5rem',
                                    padding: '8px 15px', // Reduced padding for smaller height
                                    backgroundColor: '#006638', // Success Color
                                    width: '50%', // Make the button wider
                                    textAlign: 'center', // Center text inside button
                                    margin: '0 auto' // Center the button itself
                                }}
                            >
                                {onDutyPerson.chinese_name} ({onDutyPerson.name})
                                <p style={{ fontSize: '1rem', marginTop: '5px' }}>{onDutyPerson.title}</p>
                            </button>
                        </div>
                    )}


                    {isDepartmentLoading ? (
                        <div>更新中..</div>
                    ) : (
                        getNextDepartments(startIndex).map(([department, contacts], index) => (
                            <div key={index} style={{ marginBottom: '40px' }}>
                                <h3>{department}</h3> {/* Title for department */}
                                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '20px' }}>
                                    {contacts.map((contact, index) => (
                                        <button
                                            key={index}
                                            className="btn bg-blue-dark text-white"
                                            onClick={() => handleCaptureAndSubmit(contact)} // Capture and then handle click
                                            style={{
                                                fontSize: '1.5rem',
                                                padding: '10px 15px',
                                                backgroundColor: '#0e416c',
                                            }}
                                        >
                                            {contact.chinese_name} ({contact.name})
                                            <p style={{ fontSize: '1rem', marginTop: '5px' }}>{contact.title}</p>
                                        </button>
                                    ))}
                                </div>
                            </div>
                        ))
                    )}


                </div>




            </div>


            {/* Conditionally render the input section */}
            {showInputSection && (
                <div className="input-group" style={{ fontSize: '1.25rem', height: '6vh', width: '1000px' }}>
                    <input
                        type="text"
                        className="form-control"
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                        onKeyPress={handleKeyPress}
                        placeholder="電腦打字輸入..."
                        style={{
                            fontSize: '3rem',
                            padding: '10px',
                            border: '2px solid #ccc',
                            borderRadius: '4px',
                            boxShadow: 'none'
                        }}
                    />
                    <button
                        className="btn btn-primary"
                        onClick={() => handleSendMessage(input)}
                        style={{ fontSize: '2rem', padding: '10px 20px' }}
                    >
                        發送
                    </button>
                </div>
            )}

            {/* Your existing modal and camera code here */}
            {showRecordModal && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 1050,
                    }}
                >
                    <Record
                        onClose={() => setShowRecordModal(false)} // Pass onClose function
                        onTranscription={(text) => {
                            setTranscription(text); // Update transcription state
                            setShowRecordModal(false); // Close the modal after receiving transcription
                            setInput(text); // Set transcription as input to send message
                            handleSendMessage(text); // Send message automatically
                        }}
                    />
                </div>
            )}

            {showPeopleOptionsModal && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.7)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 1040,
                    }}
                    tabIndex="-1"
                    role="dialog"
                >
                    <div
                        style={{
                            width: '90%', // Adjust width for responsiveness
                            maxWidth: '1000px',
                            height: '80vh', // Adjust height for responsiveness
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            backgroundColor: '#fff',
                            borderRadius: '8px',
                            overflow: 'hidden',
                            padding: '20px',
                        }}
                        role="document"
                    >
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                paddingBottom: '10px',
                                borderBottom: '1px solid #ccc',
                            }}
                        >
                            <h1>選擇聯絡人通話</h1>

                            {loading && (
                                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                                    <div className="spinner-border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                    <p>檔案上傳中，請稍候...</p>
                                </div>
                            )}

                            {responseData && (
                                <div className="d-flex justify-content-center mt-4">
                                    <div>
                                        <h3>AI 分析結果</h3>
                                        <p>{responseData.someField}</p>
                                    </div>
                                </div>
                            )}

                            <video
                                id="cameraVideo1"
                                style={{
                                    width: '120px',
                                    height: 'auto',
                                    borderRadius: '8px',
                                    marginLeft: '20px',
                                }}
                                autoPlay
                            ></video>

                            <button
                                type="button"
                                onClick={async () => {
                                    await handleStop();
                                    handleCloseModal(cameraStreamRef1);
                                }}
                                aria-label="Close"
                                style={{
                                    background: 'none',
                                    border: 'none',
                                    fontSize: '4rem',
                                    cursor: 'pointer',
                                    color: 'black',
                                }}
                            >
                                &times;
                            </button>
                        </div>

                        <div
                            style={{
                                flex: 1,
                                overflowY: 'auto',
                                marginTop: '10px',
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: '20px', // Adjusted gap for responsiveness
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            {peopleOptions.map((person, index) => {
                                const colorClasses = ['btn-primary', 'btn-success'];
                                const randomColorClass = colorClasses[Math.floor(Math.random() * colorClasses.length)];

                                return (
                                    <button
                                        key={index}
                                        className={`btn ${randomColorClass} text-white d-flex align-items-center justify-content-center`}
                                        onClick={() => handlePersonClick(person)}
                                        style={{
                                            width: '300px', // Adjusted width for responsiveness
                                            height: '80px', // Adjusted height for responsiveness
                                            fontSize: '1.5rem',
                                            margin: '10px',
                                            borderRadius: '8px',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <span>{person.chinese_name} ({person.name})</span>
                                        <span style={{ fontSize: '1rem', opacity: 0.8 }}>{person.department}</span>
                                    </button>
                                );
                            })}
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                paddingTop: '10px',
                                borderTop: '1px solid #ccc',
                            }}
                        >
                            <button
                                type="button"
                                onClick={async () => {
                                    await handleStop();
                                    handleCloseModal(cameraStreamRef1);
                                }}
                                style={{
                                    backgroundColor: '#6c757d',
                                    color: '#fff',
                                    padding: '20px 40px', // Adjusted padding for button
                                    border: 'none',
                                    borderRadius: '12px',
                                    cursor: 'pointer',
                                    fontSize: '1.5rem',
                                }}
                            >
                                關閉
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {showModal && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%', // Adjust for full-screen modal on mobile
                        backgroundColor: 'rgba(0, 0, 0, 0.7)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 1040,
                    }}
                    tabIndex="-1"
                    role="dialog"
                >
                    <div
                        style={{
                            width: '90%', // Adjust width for smaller screens
                            maxWidth: '900px',
                            height: 'auto', // Auto-adjust height
                            maxHeight: '80vh', // Constrain height for larger screens
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            backgroundColor: '#fff',
                            borderRadius: '8px',
                            overflow: 'hidden',
                            padding: '20px',
                        }}
                        role="document"
                    >
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                paddingBottom: '10px',
                                borderBottom: '1px solid #ccc',
                            }}
                        >
                            <button
                                type="button"
                                onClick={async () => {
                                    await handleStop();
                                    handleCloseModal(cameraStreamRef2);
                                    window.location.reload();
                                }}
                                aria-label="Close"
                                style={{
                                    background: 'none',
                                    border: 'none',
                                    fontSize: '5rem',
                                    cursor: 'pointer',
                                }}
                            >
                                &times;
                            </button>
                            <a
                                href={`${config.videoChatBaseUrl}/call?${roomID}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                    display: 'inline-block',
                                    backgroundColor: '#007bff',
                                    color: '#fff',
                                    padding: '10px 20px',
                                    textDecoration: 'none',
                                    borderRadius: '4px',
                                    marginTop: '10px',
                                }}
                            >
                                Open Room in New Tab
                            </a>
                        </div>
                        <div style={{ flex: 1, overflowY: 'auto', marginTop: '10px' }}>
                            <Room match={{ params: { roomID } }} />
                        </div>
                        <div className="transcription-display" style={{ marginTop: '20px', color: '#333333' }}>
                            {transcription && <p style={{ color: 'white' }}>{transcription}</p>}
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                paddingTop: '10px',
                                borderTop: '1px solid #ccc',
                            }}
                        >
                            <button
                                type="button"
                                onClick={async () => {
                                    await handleStop();
                                    handleCloseModal(cameraStreamRef2);
                                    window.location.reload();
                                }}
                                style={{
                                    backgroundColor: '#6c757d',
                                    color: '#fff',
                                    padding: '10px 20px',
                                    border: 'none',
                                    borderRadius: '4px',
                                    cursor: 'pointer',
                                }}
                            >
                                關閉
                            </button>
                        </div>
                    </div>
                </div>
            )}



            {showCallModal && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.7)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 1050,
                    }}
                >
                    <div
                        style={{
                            width: '90%',
                            maxWidth: '1200px', // Adjusted for better view
                            height: 'auto',
                            maxHeight: '90vh', // Allow maximum height for larger screens
                            backgroundColor: '#fff',
                            borderRadius: '12px', // Smoother border radius
                            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)', // Subtle box-shadow for depth
                            overflow: 'hidden',
                            position: 'relative',
                        }}
                    >
                        <button
                            onClick={toggleCallModal}
                            style={{
                                position: 'absolute',
                                top: '20px', // Adjusted for better spacing
                                right: '20px',
                                background: 'none',
                                border: 'none',
                                fontSize: '6rem', // Adjusted size for better UI
                                cursor: 'pointer',
                                color: '#ffffff', // Default color is now white
                                transition: 'color 0.2s ease', // Smooth transition for color change
                            }}
                            onMouseOver={(e) => (e.currentTarget.style.color = '#d3d3d3')} // Change color to light grey on hover
                            onMouseOut={(e) => (e.currentTarget.style.color = '#ffffff')} // Revert color to white on mouse out
                        >
                            &times;
                        </button>


                        {/* Embed the Whereby iframe */}
                        <div style={{ padding: '20px', textAlign: 'center' }}> {/* Padding around the iframe */}
                            <p style={{ color: '#4f4f4f' }}>剩餘時間: {Math.floor(timer / 60)}:{(timer % 60).toString().padStart(2, '0')}</p>

                            <iframe
                                src={`https://datavan.whereby.com/${config.videoRoomId}?bottomToolbar=off&lang=zh-hant&settingsButton=off&topToolbar=off&aec=on`}
                                allow="camera; microphone; fullscreen; speaker; display-capture; compute-pressure"
                                style={{
                                    width: '100%', // Make iframe responsive and take full width
                                    height: '75vh', // Make the height 75% of the viewport height
                                    border: 'none', // Remove iframe border
                                    borderRadius: '8px', // Smoother iframe border radius
                                }}
                                frameBorder="0"
                                title="Whereby Video Call"
                            ></iframe>
                        </div>
                    </div>
                </div>
            )}



        </div>
    );
}

export default Help;
